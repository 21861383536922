import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import {FaSignOutAlt} from 'react-icons/fa';
import './Logout.scss'

export default function Logout() {
    const [pendingLogoutRequest, setPendingLogoutRequest] = useState(false);

    async function logout() {
        setPendingLogoutRequest(true);
        window.user.logout();
    }

    return (
        <div className='Logout'>
            <Button icon='sign-out-alt' onClick={logout} disabled={pendingLogoutRequest}>
                <FaSignOutAlt/>
            </Button>
        </div>
    );
}