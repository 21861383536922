import React, {useState} from 'react'
import {FaRegDotCircle, FaPlay, FaStop} from 'react-icons/fa'
import Button from 'react-bootstrap/Button';
import './Service.scss'
import {getApiUrl} from "../../helpers";

export default function Service(props) {
    const [pendingRequest, setPendingRequest] = useState(false);

    async function sendRequest() {
        setPendingRequest(true);
        fetch(getApiUrl() + 'services/' + props.serviceId + (props.running ? '/stop' : '/start'), {
            cache: 'no-cache',
            credentials: 'include'
        }).then(response => {
            if (response.status === 401) {
                window.user.logout();
            } else {

                setPendingRequest(false);
            }
        });
    }

    if (props.managePermission) {
        return (
            <div className='Service'>
                <FaRegDotCircle fill={props.running ? 'green' : 'red'}/> {props.name}
                <Button disabled={pendingRequest} onClick={sendRequest}> {props.running ? <FaStop/> :
                    <FaPlay/>} </Button>
            </div>
        )
    } else {
        return (
            <div className='Service'>
                <FaRegDotCircle fill={props.running ? 'green' : 'red'}/> {props.name}
            </div>
        )
    }

}