import React from 'react'
import {getApiUrl} from "../../helpers";
import Headline from "../spareParts/Headline";
import ServiceLog from "./ServiceLog";
import './ServiceLogs.scss';


export default class ServiceLogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceLogs: []
        }
    }

    componentDidMount() {
        this.requestServiceLogs();
        this.interval = setInterval(() => this.requestServiceLogs(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    async requestServiceLogs() {
        const response = await fetch(getApiUrl() + 'servicelog', {
            cache: 'no-cache',
            credentials: 'include'
        });

        if (response.status === 401) {
            return window.user.logout()
        }
        if (!response.ok) {
            return
        }

        const data = await response.json();
        this.setState({
            serviceLogs: data.service_logs.sort((a, b) => (a.id < b.id) ? 1 : ((b.id < a.id) ? -1 : 0))
        });
    }

    render() {
        return (
            <div className='ServiceLogs'>
                <Headline text='service logs'/>
                {this.state.serviceLogs.map(log =>
                    <ServiceLog
                        key={'serviceLog-' + log.id}
                        time={log.time} user={log.user}
                        action={log.action}
                        service={log.service}
                    />
                )}
            </div>
        )
    }


}