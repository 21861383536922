import Cookies from "js-cookie";
import User from "./models/User";

export function getApiUrl() {
    if (process.env.NODE_ENV === 'development') {
        //return 'http://localhost:5000/'
        return 'https://api.pgamp.de/'
    }
    return 'https://api.pgamp.de/'
}

export function createUserIfSession() {
    console.log('trying to create user from session');
    if (Cookies.get('token')) {
        window.user = new User();
        console.log('created user from session')
    }
}