import React from 'react';
import Sidebar from '../sidebar/Sidebar';
import Services from '../services/Services';
import ServiceLogs from "../serviceLogs/ServiceLogs";
import Dashboard from '../dashboard/Dashboard';
import './ViewWrapper.scss';

export default function ViewWrapper() {
    switch (window.app.state.view) {
        case 'services': return <div className='ViewWrapper'><Sidebar/> <Services/> </div>;
        case 'serviceLogs': return <div className='ViewWrapper'><Sidebar/> <ServiceLogs/> </div>;
        case 'myuser': return <div className='ViewWrapper'><Sidebar/> <div> myuser </div> </div>;
        default: return <div className='ViewWrapper'><Sidebar/> <Dashboard/> </div>
    }
}