import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {FaSignInAlt} from 'react-icons/fa';
import {getApiUrl} from '../../helpers'
import './Login.scss';
import User from "../../models/User";

export default function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [pendingLoginRequest, setPendingLoginRequest] = useState('');

    const validateForm = () => {
        return username.length > 0 && password.length > 0;
    };

    const handleSubmit = async event => {
        event.preventDefault();
        setPendingLoginRequest(true);
        const response = await fetch(getApiUrl() + 'login', {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    'user': username,
                    'password': password
                })
            }
        );
        if (response.ok) {
            window.user = new User();
            window.app.setState({view: 'dashboard'})
        } else {
            setPendingLoginRequest(false);
        }
    };

    return (
        <div className='Login'>
            <Form onSubmit={handleSubmit}>
                <Form.Group size='lg' controlId='email'>
                    <Form.Control
                        autoFocus
                        type='text'
                        placeholder='username'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Form.Group>
                <Form.Group size='lg' controlId='password'>
                    <Form.Control
                        type='password'
                        placeholder='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>
                <Button size='lg' type='submit' disabled={!validateForm() || pendingLoginRequest}>
                    <FaSignInAlt/>
                </Button>
            </Form>
        </div>
    )
}