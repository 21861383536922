import React from 'react'
import {getApiUrl} from "../../helpers";
import Headline from '../spareParts/Headline'
import Service from './Service'
import './Services.scss'

export default class Services extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            services: []
        }
    };

    componentDidMount() {
        this.requestServicesList();
        this.interval = setInterval(() => this.requestServicesList(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    async requestServicesList() {
        const response = await
            fetch(getApiUrl() + 'services/list', {
                cache: 'no-cache',
                credentials: 'include'
            });

        if (response.status === 401) {
            return window.user.logout()
        }
        if (!response.ok) {
            return
        }

        const data = await response.json();
        this.setState({
            services: data.services
        });
    }

    render() {
        return (
            <div className='Services'>
                <Headline text='services'/>
                {this.state.services.map(service =>
                    <Service
                        key={'service-' + service.id}
                        name={service.name}
                        running={service.running}
                        managePermission={service.manageAllowed}
                        serviceId={service.id}
                    />
                )}
            </div>
        )
    }
}