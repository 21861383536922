import React from 'react';
import {
    CDBSidebar,
    CDBSidebarContent,
    CDBSidebarFooter,
    CDBSidebarHeader,
    CDBSidebarMenu,
    CDBSidebarMenuItem,
} from 'cdbreact';
import Logout from '../logout/Logout'
import './Sidebar.scss';

class Sidebar extends React.Component {
    test = () => {
        console.log('test')
    };

    render() {
        return (
            <div style={{display: 'flex', height: '100vh', overflow: 'scroll initial'}} className='Sidebar'>
                <CDBSidebar textColor='#fff' backgroundColor='#333'>
                    <CDBSidebarHeader prefix={<i className='fa fa-bars fa-large'></i>}>
                        <span style={{margin: 10}} /> my.pgamp
                    </CDBSidebarHeader>

                    <CDBSidebarContent className='sidebrear-content'>
                        <CDBSidebarMenu>
                            <button onClick={function(){window.app.setState({view: 'services'})}}>
                                <CDBSidebarMenuItem icon='server'>
                                    services
                                </CDBSidebarMenuItem>
                            </button>
                            <button onClick={function(){window.app.setState({view: 'serviceLogs'})}}>
                                <CDBSidebarMenuItem icon='stream'>
                                    service logs
                                </CDBSidebarMenuItem>
                            </button>
                        </CDBSidebarMenu>
                    </CDBSidebarContent>

                    <CDBSidebarFooter style={{textAlign: 'center'}}>
                        <Logout/>
                    </CDBSidebarFooter>
                </CDBSidebar>
            </div>
        )
    }
}

export default Sidebar;