import Cookies from 'js-cookie'
import {getApiUrl} from '../helpers'

export default class User {
    constructor() {
        this.loaded = false;
        this.load()
    }

    async load() {
        const data = await fetch(getApiUrl() + 'info', {
            cache: 'no-cache',
            credentials: 'include'
        }).then(response => response.json());

        if (typeof data.you === 'undefined') {
            return this.logout()
        }

        this.id = data.you.user.id;
        this.username = data.you.user.matrix_id.substring(1, data.you.user.matrix_id.length - 9);
        this.loaded = true;
    }

    logout() {
        delete window.user;

        fetch(getApiUrl() + 'logout', {
                cache: 'no-cache',
                credentials: 'include'
            }
        );

        window.app.setState({view: 'login'});
    }

    static getApiToken() {
        return Cookies.get('token')
    }
}