import React from 'react';
import './App.scss';
import Login from '../views/login/Login'
import {createUserIfSession} from "../helpers";
import ViewWrapper from '../views/viewWrapper/ViewWrapper'

class App extends React.Component {
    constructor(props) {
        super(props);
        window.app = this;
        createUserIfSession();
        this.state = {}
    }

    render() {
        if (this.state.view !== 'login' && typeof window.user === 'undefined') {
            this.setState({
                view: 'login'
            });
        }

        return (
            <div className='App'>
                {this.state.view === 'login' ? <Login/> : <ViewWrapper/>}
            </div>
        )
    }

}

export default App;
